import { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { OptionOfferState } from '@/contexts/OfferContext';
import { perkDefaults, perkFields, perkVisibility } from '@/constants/perks';

import { IMeInputData } from '../interfaces/IMeInputData';

export const defaults: IMeInputData = {
  flags: {},
  values: {
    total: 1500,
    stopa: 0,
    pastWork: 0,
  },
  options: {
    ...perkDefaults,
  },
};

export const propertyOrderTypes = {
  flags: [],
  values: ['total', 'stopa', 'pastWork'],
  options: [...perkFields],
} as const;

export const offerFields = ['total', 'stopa', 'pastWork', ...perkFields] as const;

export const offerFieldDefaults = {
  total: OptionOfferState.ReadOnly,
  stopa: OptionOfferState.Editable,
  pastWork: OptionOfferState.Editable,
  ...perkVisibility,
} as const;

export const propertyOrders = propertyOrderTypes as unknown as IPropertyOrders;
