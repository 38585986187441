export enum StepMe {
  PosTeret = 'me.pos.teret',
  PosPio = 'me.pos.pio',
  PosNezap = 'me.pos.nezap',
  PosFond = 'me.pos.fond',
  PosSss = 'me.pos.sss',
  PosKomora = 'me.pos.komora',
  PosPrirez = 'me.pos.prirez',
  PosZbir = 'me.pos.zbir',
  ZapPorez = 'me.zap.porez',
  ZapPio = 'me.zap.pio',
  ZapNezap = 'me.zap.nezap',
  IncTaxLow = 'me.incomeTax.low',
  IncTaxMid = 'me.incomeTax.mid',
  IncTaxHigh = 'me.incomeTax.high',
  PosContrib = 'me.pos.contributions',
  ZapContrib = 'me.zap.contributions',
}
