import { ChartData } from '@/components/shared/Chart';
import { Step } from '@/enums/Step';

import { StepMe } from '../enums/StepMe';
import { IState } from '../hooks/useTaxData';

export const chart: ChartData<IState> = [
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalCost, Step.Perks, state.calculated.perks],
    [Step.TotalCost, Step.TotalPay, state.calculated.brutoPlata],
    [Step.TotalCost, StepMe.PosTeret, state.calculated.posZbir],
    [StepMe.PosTeret, Step.Government, state.calculated.posZbir],
    [Step.TotalPay, Step.Net, state.calculated.neto],
    [Step.TotalPay, Step.Government, (state.calculated.brutoPlata - state.calculated.neto).toFixed(2)],
  ],
  null,
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalCost, Step.Perks, state.calculated.perks],
    [Step.TotalCost, Step.TotalPay, state.calculated.brutoPlata],
    [Step.TotalCost, StepMe.PosTeret, state.calculated.posZbir],

    [Step.TotalPay, Step.Net, state.calculated.neto],

    [Step.TotalPay, StepMe.ZapPorez, state.calculated.zapPorez],
    [StepMe.ZapPorez, Step.Government, state.calculated.zapPorez],
    [Step.TotalPay, StepMe.ZapPio, state.calculated.zapPio],
    [StepMe.ZapPio, Step.Government, state.calculated.zapPio],
    [Step.TotalPay, StepMe.ZapNezap, state.calculated.zapNezap],
    [StepMe.ZapNezap, Step.Government, state.calculated.zapNezap],

    [StepMe.PosTeret, StepMe.PosPio, state.calculated.posPio],
    [StepMe.PosPio, Step.Government, state.calculated.posPio],
    [StepMe.PosTeret, StepMe.PosNezap, state.calculated.posNezap],
    [StepMe.PosNezap, Step.Government, state.calculated.posNezap],
    [StepMe.PosTeret, StepMe.PosFond, state.calculated.posFond],
    [StepMe.PosFond, Step.Government, state.calculated.posFond],
    [StepMe.PosTeret, StepMe.PosSss, state.calculated.posSss],
    [StepMe.PosSss, Step.Government, state.calculated.posSss],
    [StepMe.PosTeret, StepMe.PosKomora, state.calculated.posKomora],
    [StepMe.PosKomora, Step.Government, state.calculated.posKomora],
    [StepMe.PosTeret, StepMe.PosPrirez, state.calculated.posPrirez],
    [StepMe.PosPrirez, Step.Government, state.calculated.posPrirez],
  ],
];
