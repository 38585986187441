import { useCallback, useMemo, useState } from 'react';

import { parseFromUrl, serializeData } from '@/utils/serializer';
import { OptionOfferState } from '@/contexts/OfferContext';
import { perks as perksList } from '@/constants/perks';
import { useKeys } from '@/contexts/KeysContext';
import { setFieldFactory } from '@/utils/set-field-factory';
import { isInReadonlyArray } from '@/utils/is-in-readonly-array';

import { defaults, propertyOrders } from '../constants/defaults';
import { IMeInputData } from '../interfaces/IMeInputData';
import { Brand } from '../../../../enums/Brand';

const OPCINE = [
  {
    name: 'Podgorica',
    value: 15,
  },
  {
    name: 'Cetinje',
    value: 15,
  },
  {
    name: 'Budva',
    value: 10,
  },
  {
    name: 'me.otherCounties',
    value: 13,
  },
];

export const opcine = OPCINE.map(({ name }, value) => ({ value, name }));

interface IComputed {
  bruto1: number;
  opcina: number;
  trosak?: number;
}

export function useTaxData(
  brand: Brand,
  offerFields?: ReadonlyArray<string>,
  calculatorOptions?: Record<string, unknown>,
  urlData: string = window.location.hash.slice(1),
) {
  const taxChange2024 = calculatorOptions?.taxChange2024 as boolean;
  const DEFAULT_STATE = serializeData(
    propertyOrders,
    defaults,
    () => {
      /* noop */
    },
    brand,
  );

  const { setKey } = useKeys();
  const [inputData, setInputData] = useState(() => parseFromUrl(propertyOrders, defaults, brand, offerFields));
  const [setField] = useState(() => setFieldFactory<IMeInputData>(setInputData));

  const { options } = inputData;
  const perks = options
    ? perksList
        .filter(({ countries }) => isInReadonlyArray('me', countries))
        .map(({ key }) => options[key])
        .filter(({ flag }) => flag)
        .reduce((acc, curr) => acc + curr.value, 0)
    : 0;

  const minuliRad = useMemo(() => {
    // const { pastWork } = inputData.values;
    const pastWork = 0;

    if (pastWork < 10) {
      return pastWork * 0.5;
    } else if (pastWork < 20) {
      return 5 + (pastWork - 10) * 0.75;
    } else {
      return 17.5 + (pastWork - 20) * 1;
    }
  }, []);

  const computed: IComputed = useMemo(
    () => ({
      bruto1: inputData.values.total / (1 + minuliRad / 100) - perks,
      opcina: OPCINE[inputData.values.stopa].value,
    }),
    [inputData, perks, minuliRad],
  );

  // const skipData = useSkip(computed);

  const calculated = useMemo(() => {
    const brutoPlata = computed.bruto1;
    const prirezPoreza = computed.opcina;
    const posPrirez1 = prirezPoreza;

    let zapPorezPosto = 0;
    let razlika = 0;
    let zapPorezAdd = 0;

    if (brutoPlata > 700.0 && brutoPlata <= 1000.0) {
      zapPorezPosto = 9;
      razlika = brutoPlata - 700.0;
    } else if (brutoPlata > 1000.0) {
      zapPorezPosto = 15;
      razlika = brutoPlata - 1000.0;
      zapPorezAdd = 27;
    }

    const zapPorez = (razlika * zapPorezPosto) / 100 + zapPorezAdd;
    const zapPio = brutoPlata * (taxChange2024 ? 0.1 : 0.15);
    const zapNezap = brutoPlata * 0.005;
    const neto = brutoPlata - zapPorez - zapPio - zapNezap;
    const posPio = brutoPlata * (taxChange2024 ? 0 : 0.055);
    const posNezap = brutoPlata * 0.005;
    const posFond = brutoPlata * (taxChange2024 ? 0 : 0.002);
    const posKomora = brutoPlata * 0.0027;
    const posSss = brutoPlata * 0.002;
    const posPrirez = (zapPorez * prirezPoreza) / 100;
    const posZbir = posPio + posNezap + posFond + posPrirez + posKomora + posSss;

    return {
      perks,
      neto,
      zapPio,
      zapNezap,
      zapPorezPosto,
      zapPorez,
      posPio,
      posNezap,
      posFond,
      posKomora,
      posSss,
      posZbir,
      posPrirez,
      posPrirez1,
      brutoPlata,
    };
  }, [computed, perks, taxChange2024]);

  computed.trosak = computed.bruto1 + calculated.posZbir;

  const defaultState = useMemo(() => DEFAULT_STATE === urlData || urlData === '', [urlData, DEFAULT_STATE]);

  const state = useMemo(
    () => ({ ...inputData, computed, calculated, defaultState }),
    [calculated, inputData, computed, defaultState],
  );

  const serialize = useCallback(
    (offerState?: Record<string, OptionOfferState>) => {
      return serializeData(propertyOrders, inputData, setKey, brand, 'me', offerState, offerFields);
    },
    [offerFields, inputData, setKey, brand],
  );

  return { state, setInputData, setField, serialize };
}

export type ITaxData = ReturnType<typeof useTaxData>;

export type IState = ITaxData['state'];
