import { useTranslation } from 'react-i18next';

import { SectionHeader } from '@/components/shared/SectionHeader';
import { chart } from '@/components/features/Montenegro/constants/chart';
import { StepMe } from '@/components/features/Montenegro/enums/StepMe';
import { Step } from '@/enums/Step';
import { AmountField } from '@/components/shared/fields/AmountField';
import { Summary } from '@/components/shared/Summary';
import { Segment } from '@/components/shared/Segment';
import { Eur } from '@/constants/currencies';
import { Footer } from '@/components/shared/Footer';
import { Perks } from '@/components/shared/Perks';
import { Sticky } from '@/components/shared/Sticky';
import { SelectField } from '@/components/shared/fields/SelectField';
import { CurrencyProvider } from '@/contexts/CurrencyContext';
import { OfferProvider } from '@/contexts/OfferContext';
import { ContentLayout } from '@/components/shared/Layout/ContentLayout';
import { Header } from '@/components/shared/Header/Header';
import { hasProp } from '@/utils/has-prop';

import { fieldsPerDetails, posAmountFields, zapAmountFields } from './constants/fields';
import { opcine } from './hooks/useTaxData';
import { useMeParams } from './hooks/useMeParams';
import { useBrand } from '../../../hooks/useBrand';
import { Forbidden } from '../../shared/Forbidden';
import { Box, Switch } from '@chakra-ui/react';
import { EntryRow } from '../../shared/EntryRow';
import { useState } from 'react';

export const Montenegro = ({ isInternal, isOffer }: { isInternal?: boolean; isOffer?: boolean }) => {
  const brand = useBrand();
  const [taxChange2024, setTaxChange2024] = useState(true);
  const { urlParams, offerValue, state, setField } = useMeParams(brand.brand, isInternal, isOffer, { taxChange2024 });
  const { t } = useTranslation();

  if (!isOffer && brand.countries.includes('me') === false) {
    return <Forbidden inline />;
  }

  return (
    <CurrencyProvider local={Eur} primary={Eur}>
      <OfferProvider value={offerValue}>
        <Header isInternal={isInternal} isOffer={isOffer} urlParams={urlParams} state={state} chart={chart} />

        <ContentLayout>
          <Sticky>
            <AmountField
              highlighted={isOffer}
              hint={state.defaultState ? (t('common.totalPayHint') as string) : undefined}
              title={t('common.totalPay')}
              value={state.values.total}
              onChange={(value) => setField('total', value)}
              name="total"
            />
          </Sticky>
          <Segment>
            {/* <NumberField
              title={`${t('me.pastWork')}`}
              value={state.values.pastWork}
              onChange={(value) => setField('pastWork', value)}
              round
              name="pastWork"
            /> */}
            <SelectField
              title={t('me.county')}
              value={state.values.stopa}
              onChange={(value) => setField('stopa', value)}
              name="stopa"
              options={opcine}
            />
            <EntryRow title={t('me.2024TaxChangeToggle')}>
              <Box textAlign="right">
                <Switch
                  size="lg"
                  colorScheme="brand"
                  defaultChecked={taxChange2024}
                  onChange={(e) => setTaxChange2024(e.target.checked)}
                />
              </Box>
            </EntryRow>
          </Segment>

          <Perks state={state} setField={setField} country="me" />

          <Segment>
            <SectionHeader title={t('common.amounts')} />
            <AmountField title={t(Step.Gross)} value={state.calculated.brutoPlata} />
            {/* TODO: trosak defaults to 0? */}
            <AmountField title={t(Step.TotalCost)} value={state.computed.trosak ?? 0} />
            <AmountField title={t(Step.Net)} highlighted value={state.calculated.neto} />
            <Summary label={Step.Net} value={state.calculated.neto} />
          </Segment>

          <Segment>
            <SectionHeader title={t(StepMe.ZapContrib)} />
            {zapAmountFields(taxChange2024)
              .filter(({ field }) => fieldsPerDetails[0].indexOf(field) !== -1)
              .filter(({ field }) => state.calculated[field])
              .map(({ label, field, highlighted, description, calculateValue, calculateDesc }) => {
                const calculatedValue = calculateValue
                  ? calculateValue(state.calculated[field])
                  : state.calculated[field];

                const calculatedDescription =
                  calculateDesc && description && hasProp(state.calculated, description)
                    ? calculateDesc(state.calculated[description])
                    : description;

                return (
                  <AmountField
                    highlighted={highlighted}
                    title={t(label)}
                    key={label}
                    value={calculatedValue}
                    description={calculatedDescription}
                  />
                );
              })}
          </Segment>

          <Segment>
            <SectionHeader title={t(StepMe.PosContrib)} />
            {posAmountFields(taxChange2024)
              .filter(({ field }) => fieldsPerDetails[0].indexOf(field) !== -1)
              .filter(({ field }) => state.calculated[field] !== undefined)
              .map(({ label, field, highlighted, description, calculateValue, calculateDesc }) => {
                const calculatedValue = calculateValue
                  ? calculateValue(state.calculated[field])
                  : state.calculated[field];

                const calculatedDescription =
                  calculateDesc && description && hasProp(state.calculated, description)
                    ? calculateDesc(state.calculated[description])
                    : description;

                return (
                  <AmountField
                    highlighted={highlighted}
                    title={t(label)}
                    key={label}
                    value={calculatedValue}
                    description={calculatedDescription}
                  />
                );
              })}
          </Segment>

          {/* <Summary label={Step.Net} value={state.calculated.neto} /> */}
          <Footer label="me.footer" isOffer={isOffer} state={state} chart={chart} />
        </ContentLayout>
      </OfferProvider>
    </CurrencyProvider>
  );
};
