import { useParams } from '@/hooks/useParams';

import { useTaxData } from '../hooks/useTaxData';
import { ITaxData as IMeTaxData } from '../hooks/useTaxData';
import { offerFieldDefaults, offerFields } from '../constants/defaults';
import { Brand } from '../../../../enums/Brand';

export function useMeParams(brand: Brand, isInternal = false, isOffer = false, options?: Record<string, unknown>) {
  return useParams<IMeTaxData>({ brand, isInternal, isOffer, offerFields, offerFieldDefaults, useTaxData, options });
}
