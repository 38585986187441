import { Center, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CenterLayout from './Layout/CenterLayout';

export const Forbidden = ({ inline }: { inline?: boolean }) => {
  const { t } = useTranslation();

  return inline ? (
    <Center mt={4}>
      <Heading as="h1">{t('common.error.forbidden')}</Heading>
    </Center>
  ) : (
    <CenterLayout languages={['en']}>
      <Center mt={4}>
        <Heading as="h1">{t('common.error.forbidden')}</Heading>
      </Center>
    </CenterLayout>
  );
};
