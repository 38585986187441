import { IAmountFields } from '../interfaces/IAmountFields';
import { StepMe } from '../enums/StepMe';

export const zapAmountFields: (taxChange2024: boolean) => Array<IAmountFields> = (taxChange2024: boolean) => [
  {
    label: StepMe.ZapPorez,
    field: 'zapNezap',
  },
  {
    label: StepMe.IncTaxLow,
    field: 'brutoPlata',
    description: '0%',
    calculateValue: (_value) => 0,
  },
  {
    label: StepMe.IncTaxMid,
    field: 'brutoPlata',
    description: '9%',
    calculateValue: (value) => 0.09 * Math.max(0, Math.min(1000, value) - 700),
  },
  {
    label: StepMe.IncTaxHigh,
    field: 'brutoPlata',
    description: '15%',
    calculateValue: (value) => 0.15 * Math.max(0, value - 1000),
  },
  {
    label: StepMe.ZapPio,
    field: 'zapPio',
    description: taxChange2024 ? '10%' : '15%',
  },
  {
    label: StepMe.ZapNezap,
    field: 'zapNezap',
    description: '0.5%',
  },
];

export const posAmountFields: (taxChange2024: boolean) => Array<IAmountFields> = (taxChange2024: boolean) => [
  {
    label: StepMe.PosPio,
    field: 'posPio',
    description: taxChange2024 ? '0%' : '5.5%',
  },
  {
    label: StepMe.PosNezap,
    field: 'posNezap',
    description: '0.5%',
  },
  {
    label: StepMe.PosFond,
    field: 'posFond',
    description: taxChange2024 ? '0%' : '0.2%',
  },
  {
    label: StepMe.PosSss,
    field: 'posSss',
    description: '0.2%',
  },
  {
    label: StepMe.PosKomora,
    field: 'posKomora',
    description: '0.27%',
  },
  {
    label: StepMe.PosPrirez,
    field: 'posPrirez',
    description: 'posPrirez1',
    calculateDesc: (value) => `${value}%`,
  },
  {
    label: StepMe.PosZbir,
    field: 'posZbir',
    description: '0.27%',
  },
];

export const fieldsPerDetails = [
  [
    'trosak',
    'brutoPlata',
    'zapPio',
    'zapNezap',
    'posPio',
    'posNezap',
    'posFond',
    'posSss',
    'posKomora',
    'posPrirez',
    'posPrirez1',
    'posZbir',
  ],
];
