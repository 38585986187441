import { Box, Center, Heading, HStack, Show, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LanguageSelection } from '@/components/shared/selections/LanguageSelection';
import { ReactNode } from 'react';
import { useBrand } from '../../../hooks/useBrand';
import { Brand } from '../../../enums/Brand';

const Login = ({ children, title, languages }: { children: ReactNode; title?: string; languages?: Array<string> }) => {
  const { t } = useTranslation();
  const color = useColorModeValue('black', 'white');
  const boxColor = useColorModeValue('white', 'black');
  const bgColor = useColorModeValue('gray.200', 'gray.800');
  const brand = useBrand();
  const logo = useColorModeValue(brand.logo.white, brand.logo.black);
  const pageTitle = brand?.brand === Brand.Infinum ? t('common.title2') : t('common.title');

  return (
    <Center w="100%" h="100%" bg={bgColor}>
      <Box maxW={title ? 960 : 320} borderRadius={8} p={6} m={4} bg={boxColor}>
        <Heading as="h1" fontSize={['4xl', '2xl']} color={color}>
          {title ? (
            <Show above="sm">
              <>{title}</>
            </Show>
          ) : (
            <HStack>
              <Box h={5} as="img" role="presentation" src={logo} mr={2} />
              <Show above="sm">
                <>{pageTitle}</>
              </Show>
            </HStack>
          )}
        </Heading>
        <Box overflowY="auto" maxH="80vh">
          {children}
        </Box>
        {(!languages || languages.length > 0) && (
          <Center mt={4}>
            <LanguageSelection limitLanguages={languages ?? brand.languages} />
          </Center>
        )}
      </Box>
    </Center>
  );
};

export default Login;
